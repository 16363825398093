import CountdownComponent from './countdown.component'

export default class TidesComponent {
    static init() {
        if ($('.wp-block-tide').length > 0) {
            import(
                './datatable.component' /* webpackChunkName: "scripts/DatatableComponent" */
            ).then(({ default: DatatableComponent }) => {
                new DatatableComponent()
            })
            import(
                './datepicker.component' /* webpackChunkName: "scripts/DatepickerComponent" */
            ).then(({ default: DatepickerComponent }) => {
                new DatepickerComponent()
            })
        }
        new CountdownComponent()
    }
}
