const CountdownComponent = class CountdownComponent {
    static timerElement = null

    constructor() {
        CountdownComponent.timerElement = $('[data-tides-timer]')

        if (CountdownComponent.timerElement.length > 0) {
            CountdownComponent.timerElement.each((index, value) => {
                const script = document.createElement('script')
                script.type = 'text/javascript'
                script.src =
                    'https://cdnjs.cloudflare.com/ajax/libs/countdown/2.6.0/countdown.min.js'
                document.body.appendChild(script)
                script.onload = () => {
                    CountdownComponent.update($(value))
                }
            })
        }
    }

    static update(theTimerElement) {
        /* Get all parameters */
        const year = Number.parseInt(theTimerElement.attr('data-tides-timer-year'))
        const month = Number.parseInt(theTimerElement.attr('data-tides-timer-month'))
        const day = Number.parseInt(theTimerElement.attr('data-tides-timer-day'))
        const hours = Number.parseInt(theTimerElement.attr('data-tides-timer-hours'))
        const minutes = Number.parseInt(theTimerElement.attr('data-tides-timer-minutes'))
        const seconds = Number.parseInt(theTimerElement.attr('data-tides-timer-seconds'))
        const hoursSeparator = theTimerElement.attr('data-tides-timer-hour-separator')
        const minutesSeparator = theTimerElement.attr('data-tides-timer-minute-separator')
        const secondsSeparator = theTimerElement.attr('data-tides-timer-second-separator')
        const withSecond = theTimerElement.attr('data-tides-timer-with-second')

        /* Calculate timespan */
        const timeSpan = countdown(new Date(year, month, day, hours, minutes, seconds), null)

        /* Construct separator */
        const hoursSeparatorPluralized =
            hoursSeparator.indexOf(':') === -1 && timeSpan.hours > 1
                ? `${hoursSeparator}`
                : hoursSeparator
        const minutesSeparatorPluralized =
            minutesSeparator.indexOf(':') === -1 && timeSpan.minutes > 1
                ? `${minutesSeparator}`
                : minutesSeparator
        const secondsSeparatorPluralized =
            secondsSeparator.indexOf(':') === -1 && timeSpan.seconds > 1
                ? `${secondsSeparator}`
                : secondsSeparator
        const hoursSeparatorRendered =
            hoursSeparator.indexOf(':') === -1
                ? ` ${hoursSeparatorPluralized} `
                : hoursSeparatorPluralized
        const minutesSeparatorRendered =
            minutesSeparator.indexOf(':') === -1
                ? ` ${minutesSeparatorPluralized} `
                : minutesSeparatorPluralized
        const secondsSeparatorRendered =
            secondsSeparator.indexOf(':') === -1
                ? ` ${secondsSeparatorPluralized} `
                : secondsSeparatorPluralized

        /* Construct part of timer */
        const hoursRendered =
            timeSpan.hours.toString().length < 2 && hoursSeparatorRendered.indexOf(':') !== -1
                ? `0${timeSpan.hours}`
                : timeSpan.hours
        const minutesRendered =
            timeSpan.minutes.toString().length < 2 && minutesSeparatorRendered.indexOf(':') !== -1
                ? `0${timeSpan.minutes}`
                : timeSpan.minutes
        const secondsRendered =
            timeSpan.seconds.toString().length < 2 ? `0${timeSpan.seconds}` : `${timeSpan.seconds}`

        const hoursVisibility =
            timeSpan.hours !== 0
                ? `${hoursRendered}${hoursSeparatorRendered}`
                : hoursSeparatorRendered.indexOf(':') !== -1
                  ? `${hoursRendered}${hoursSeparatorRendered}`
                  : ''
        const minutesVisibility =
            timeSpan.minutes !== 0
                ? `${minutesRendered}${minutesSeparatorRendered}`
                : minutesSeparatorRendered.indexOf(':') !== -1
                  ? `${minutesRendered}${minutesSeparatorRendered}`
                  : ''
        const secondsVisibility =
            timeSpan.seconds !== 0
                ? `${secondsRendered}${secondsSeparatorRendered}`
                : secondsSeparatorRendered.indexOf(':') !== -1
                  ? `${secondsRendered}${secondsSeparatorRendered}`
                  : `${secondsRendered}${secondsSeparatorRendered}`

        /* Render timer HTML */
        if (withSecond === 'true') {
            theTimerElement.html(
                `${hoursVisibility}${minutesVisibility}${secondsVisibility}`.trim(),
            )
        } else {
            theTimerElement.html(`${hoursVisibility}${minutesVisibility}`.trim())
        }

        /* Launch timer */
        requestAnimationFrame(() => {
            CountdownComponent.update(theTimerElement)
        })
    }
}

export default CountdownComponent
